<template>
	<v-card min-height="457" height="100%" class="card-border">
		<v-card-text v-if="sticker">
			<template v-if="sticker.isSlotConfigured">
				<!-- CUSTOM STICKER VIEW -->
				<v-row justify="center" align="center" class="sticker-header mb-0 mb-lg-3">
					<v-col @mouseleave="editToggles.showNameEdit = false" cols="9" class="mb-3">
						<h4
							@mouseenter="editToggles.showNameEdit = true"
							v-if="!editToggles.showNameInput"
							class="fs--18 fw--300 text-center">
							{{ sticker.name }}
						</h4>

						<v-icon
							@click="actions.showInput"
							v-show="editToggles.showNameEdit && !editToggles.showNameInput"
							size="22"
							color="pink"
							class="name-edit-icon">
							{{ icons.pencilIcon }}
						</v-icon>

						<v-text-field
							v-if="editToggles.showNameInput"
							placeholder="Edit Sticker Name"
							v-model="stickerName"
							max
							:append-icon="icons.check"
							@click:append="actions.setStickerName"
							@keyup.enter="actions.setStickerName"
							@keyup.esc="actions.cancelStickerName"
							style="margin-top: -17px"
							v-click-outside="actions.cancelStickerName"
							:rules="stickerNameRules"
							color="pink"></v-text-field>
					</v-col>
					<v-icon size="36" color="grey" class="drag-icon">
						{{ icons.drag }}
					</v-icon>
					<div class="arrows-move d-flex flex-column mt-10">
						<v-btn icon x-small @click="$emit('onSortFirst', sticker.uuid)">
							<v-icon size="28" color="grey">
								{{ icons.arrowUp }}
							</v-icon>
						</v-btn>
						
						<v-btn icon x-small @click="$emit('onSortLast', sticker.uuid)">
							<v-icon size="28" color="grey">
								{{ icons.arrowDown }}
							</v-icon>
						</v-btn>
					</div>
				</v-row>
				<!-- CUSTOM STICKER VIEW -->
				<div class="text-center sticker-image" @mouseleave="editToggles.showImageEdit = false">
					<div v-if="!sticker.enabled" :class="!sticker.enabled && 'disabled-image'"></div>
					<v-img
						max-width="350"
						height="120"
						style="margin: auto"
						contain
						:src="stickerImageSrc"
						@mouseenter="editToggles.showImageEdit = true"
						ref="stickerImage"></v-img>
					<!-- <v-icon
						@click="actions.pickImageFile"
						v-if="editToggles.showImageEdit"
						size="24"
						color="pink"
						class="edit-icon">
						{{ icons.pencilIcon }}
					</v-icon> -->

					<v-btn color="pink" x-small class="mt-3" @click="actions.pickImageFile">Edit</v-btn>

					<input
						type="file"
						accept="image/png,image/gif"
						style="display: none"
						@change="actions.onImagePick"
						ref="imageInput" />
				</div>
				<template>
					<div class="d-flex justify-space-between align-center my-2">
						<span class="fs--14 text-md fw--500 mt-2">Enable:</span>
						<v-switch class="ma-0" v-model="sticker.enabled" hide-details small color="pink"></v-switch>
					</div>
					<div class="d-flex justify-space-between align-center mb-1">
						<span class="fs--14 text-md fw--500">Custom Placement:</span>
						<v-switch
							v-model="sticker.isStickerPlacementEnabled"
							class="ma-0"
							hide-details
							small
							color="pink"></v-switch>
					</div>
				</template>

				<v-row align="center" no-gutters>
					<v-col cols="5" md="5" xl="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Stickiness:
						</span>
					</v-col>
					<v-col cols="7" md="7" xl="8" class="text-right">
						<v-slider
							v-model="stickiness"
							@start="actions.onRangeDataStart"
							@end="actions.onRangeDataEnd"
							color="pink"
							:min="10"
							:max="45"
							:disabled="!sticker.enabled"
							:thumb-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							hide-details>
							<template #thumb-label>
								<span class="px-2">{{ stickiness }}</span>
							</template>
						</v-slider>
					</v-col>
					<v-col cols="5" md="5" xl="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Volume:
						</span>
					</v-col>
					<v-col cols="7" md="7" xl="8" class="text-right">
						<v-slider
							v-model="sticker.volume"
							@start="actions.onRangeDataStart"
							@end="actions.onRangeDataEnd"
							color="pink"
							:min="0"
							:max="100"
							:disabled="!sticker.enabled"
							:thumb-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							hide-details>
							<template #thumb-label>
								<span class="px-2">{{ sticker.volume }}%</span>
							</template>
						</v-slider>
					</v-col>
				</v-row>
				<v-row no-gutters class="mt-3" align="center">
					<v-col cols="6" class="pr-1">
						<v-btn
							@click="actions.playAudio"
							:small="$vuetify.breakpoint.lgOnly"
							:large="$vuetify.breakpoint.xlOnly"
							color="secondary"
							:disabled="!sticker.enabled"
							depressed
							block
							class="grid-btn py-4 px-3">
							<v-icon>{{ icons.volume }}</v-icon>
							<span class="text-xs">Play</span>
						</v-btn>
					</v-col>
					<v-col cols="6" class="pl-1">
						<v-btn
							@click="actions.pickAudioFile"
							:small="$vuetify.breakpoint.lgOnly"
							depressed
							color="secondary"
							outlined
							:disabled="!sticker.enabled"
							block
							class="grid-btn py-4 px-3">
							<v-icon>{{ icons.volume }}</v-icon>
							<span class="textColor--text text-xs">Replace</span>
						</v-btn>
					</v-col>
					<input
						type="file"
						accept="audio/mpeg,audio/wav,audio/x-wav"
						class="d-none"
						style="display: none"
						@change="actions.onAudioPick"
						ref="audioInput" />
				</v-row>
				<v-row align="center">
					<v-col cols="4" md="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Bits:
						</span>
					</v-col>
					<v-col cols="8" md="8" class="text-right">
						<BitsDropdown
							:sku="sticker.sku"
							:disabled="!sticker.enabled"
							@onChange="actions.setStickerSku"></BitsDropdown>
					</v-col>
				</v-row>

				<div v-if="isBitsEnabled" class="d-flex justify-space-between align-center">
					<span class="fs--14 fw--500 mt-2">Sub-Only:</span>
					<v-switch
						v-model="sticker.subOnly"
						:disabled="!sticker.enabled"
						hide-details
						small
						color="pink"></v-switch>
				</div>

				<div class="mt-4">
					<div class="d-flex align-center justify-space-between">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Search Terms ({{ sticker.searchTerms ? sticker.searchTerms.length : 0 }})
						</span>
						<v-btn color="secondary" depressed x-small @click="actions.viewAllTags">
							<v-icon size="18" class="mr-1">{{ icons.pencilIcon }}</v-icon>
							Edit
						</v-btn>
					</div>
				</div>

				<v-row no-gutters justify="center" class="mt-3">
					<v-col cols="10" md="9" class="pr-1">
						<v-btn
							@click="actions.previewSticker"
							:disabled="!isExtensionOnline || !sticker.enabled"
							depressed
							color="pink"
							block>
							<span>Preview</span>
						</v-btn>
					</v-col>
					<v-col :cols="2" :lg="3" class="pl-1 text-right">
						<v-btn
							@click="actions.onDeleteSticker(sticker.uuid)"
							color="secondary"
							depressed
							class="white--text px-0"
							style="min-width: 50px"
							:disabled="!sticker.enabled">
							<v-icon>{{ icons.deleteIcon }}</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</template>
		</v-card-text>

		<GenericPopup v-if="errorDialog.show" :modal="errorDialog.show" @close="errorDialog.show = false">
			<template #primaryHeader>{{ errorDialog.primaryHeader }}</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					{{ errorDialog.copy }}
				</p>
			</template>
		</GenericPopup>
	</v-card>
</template>

<script>
import axios from '@axios';
import { computed, reactive, ref } from '@vue/composition-api';
import { StickerType } from '@/utils/constants';
import { getUserId, isBitsEnabled } from '@/utils/auth';
import { getGlobalStickerAssetPath, getCustomStickerAssetPath } from '@/utils/config.js';
import {
	onImagePicked,
	onAudioPicked,
	createObjectUrlFromBlob,
	parseBits,
	rangeValueConverter,
} from '@/utils/sticker.js';
import BitsDropdown from '@/views/shared/BitsDropdown.vue';
import GenericPopup from '@/components/GenericPopup';
import {
	mdiChevronDown,
	mdiDelete,
	mdiDrag,
	mdiLock,
	mdiPlus,
	mdiVolumeLow,
	mdiPencil,
	mdiClose,
	mdiCheck,
	mdiChevronDoubleDown,
	mdiChevronDoubleUp,
} from '@mdi/js';

export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		stickerType: {
			type: String,
			required: true,
		},
		isExtensionOnline: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BitsDropdown,
		GenericPopup,
	},
	emits: [
		'onNoBitsTryUpload',
		'onDeleteSticker',
		'onRangeDataStart',
		'onRangeDataEnd',
		'onOpenTwitterFollow',
		'onOpenDiscordFollow',
		'onViewAllTags',
		'onSortFirst',
		'onSortLast',
	],
	setup(props, ctx) {
		const stickerNameRules = [
			v => !!v || 'Sticker name Required',
			v => v.length <= 25 || 'Max 25 characters allowed.',
		];

		const editToggles = reactive({
			showImageEdit: false,
			showNameEdit: false,
			showNameInput: false,
		});

		const errorDialog = reactive({
			show: false,
			primaryHeader: 'Error',
			copy: 'An error occurred.',
		});

		// Data values for custom value handling.
		const stickerName = ref(props.sticker.name);

		const imageInput = ref(null);
		const stickerImage = ref(null);

		const replacedImage = ref(null);
		const stickerImageSrc = computed(() => {
			if (!replacedImage.value) {
				return `${actions.getImagePath()}/${props.sticker.staticView}`;
			} else {
				return replacedImage.value;
			}
		});

		// Calculated UI value for stickiness.
		const stickiness = computed({
			get() {
				return rangeValueConverter.toUiValue(props.sticker.stickiness, 10, 45, 100);
			},
			set(newValue) {
				props.sticker.stickiness = rangeValueConverter.toDbValue(newValue, 10, 45, 100);
			},
		});

		const audioInput = ref(null);
		let player = null;
		let changedAudioUrl = null;

		const actions = {
			// Puts the sticker name into edit mode.
			showInput: function () {
				editToggles.showNameInput = true;
			},
			// Opens the browse for local file dialog.
			pickImageFile: function () {
				imageInput.value.click();
			},
			// Called after an image is selected from the file dialog;
			onImagePick: function (e) {
				const afterScale = () => {
					if (props.sticker.stickerBlob) {
						replacedImage.value = createObjectUrlFromBlob(props.sticker.stickerBlob);
					}
				};

				const onScaleError = error => {
					if (error.response) {
						errorDialog.copy = error.response.data.error;
					} else {
						errorDialog.copy = error;
					}
					errorDialog.show = true;
				};

				onImagePicked(e, props.sticker, afterScale, onScaleError);
			},
			// Called after the sticker name is edited.
			setStickerName: function () {
				if (stickerName.value.length <= 25) {
					props.sticker.name = stickerName.value;
				} else {
					stickerName.value = props.sticker.name;
				}
				editToggles.showNameInput = false;
			},
			cancelStickerName: function () {
				stickerName.value = props.sticker.name;
				editToggles.showNameInput = false;
			},
			// Called after the # of bits is selected.
			setStickerSku: function (item) {
				props.sticker.sku = item.value;
			},
			getImagePath: function () {
				if (props.stickerType == StickerType.GLOBAL) {
					return `${getGlobalStickerAssetPath()}`;
				} else {
					return `${getCustomStickerAssetPath(getUserId())}`;
				}
			},
			playAudio: function () {
				let url;
				if (changedAudioUrl) {
					url = changedAudioUrl;
				} else if (props.sticker.sound) {
					if (props.stickerType == StickerType.GLOBAL) {
						url = getGlobalStickerAssetPath() + '/' + props.sticker.sound;
					} else {
						url = getCustomStickerAssetPath(getUserId()) + '/' + props.sticker.sound;
					}
				} else {
					url = '/sounds/default_sound.mp3';
				}

				player = new Audio(url);
				player.volume = props.sticker.volume / 100;
				player.play();
			},
			previewSticker: function () {
				let url;
				if (props.stickerType == StickerType.GLOBAL) {
					url = `/global-sticker/preview/${props.sticker.uuid}`;
				} else {
					url = `/custom-sticker/preview/${props.sticker.uuid}`;
				}
				axios.post(url).catch(error => console.log(error));
			},
			// Opens the browse for local file dialog.
			pickAudioFile: function () {
				audioInput.value.click();
			},
			onAudioPick: function (e) {
				const afterPicked = () => {
					if (props.sticker.audioBlob) {
						changedAudioUrl = createObjectUrlFromBlob(props.sticker.audioBlob);
					}
				};

				const onError = error => {
					if (error.response) {
						errorDialog.copy = error.response.data.error;
					} else {
						errorDialog.copy = error;
					}
					errorDialog.show = true;
				};

				onAudioPicked(e, props.sticker, afterPicked, onError);
			},
			onDeleteSticker: function (uuid) {
				ctx.emit('onDeleteSticker', uuid);
			},
			onLockedClick: function (sticker) {
				if (sticker.isTwitterSlot) {
					ctx.emit('onOpenTwitterFollow', true);
					return;
				}
				if (sticker.isDiscordSlot) {
					ctx.emit('onOpenDiscordFollow', true);
					return;
				} else if (!isBitsEnabled.value) {
					ctx.emit('onNoBitsTryUpload');
				}
			},
			viewAllTags() {
				ctx.emit('onViewAllTags', props.sticker);
			},
			// A slider has started to change. We check this to disable the watch on
			// data changes until the final change is complete to avoid many server calls.
			onRangeDataStart: function () {
				ctx.emit('onRangeDataStart', props.sticker);
			},
			onRangeDataEnd: function () {
				ctx.emit('onRangeDataEnd', props.sticker);
			},
		};

		return {
			editToggles,
			errorDialog,
			actions,
			stickerName,
			stickerImage,
			stickerImageSrc,
			stickiness,
			imageInput,
			audioInput,
			parseBits,
			isBitsEnabled,
			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			isTTS: props.stickerType == StickerType.TTS,
			stickerNameRules,
			icons: {
				closeIcon: mdiClose,
				pencilIcon: mdiPencil,
				deleteIcon: mdiDelete,
				plusIcon: mdiPlus,
				arrow: mdiChevronDown,
				volume: mdiVolumeLow,
				lock: mdiLock,
				drag: mdiDrag,
				check: mdiCheck,
				arrowUp: mdiChevronDoubleUp,
				arrowDown: mdiChevronDoubleDown,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.card-border {
	border: 1px solid transparent;
}

.card-border:hover {
	border: 1px solid #4a93d9;
}

.upload-area {
	height: 315px;
}

.arrows-move {
	position: absolute;
	right: 3px;
	top: -6px;
	z-index: 99 !important;
}
.upload-btn,
.lock-btn {
	position: absolute;
	bottom: 20px;
	left: 5%;
	width: 90%;
}

.unlock-area {
	height: 298px;
}

.sticker-header {
	position: relative;
	height: 70px;
	z-index: 0;
}

.global-sticker-header {
	position: relative;
	height: 60px;
	z-index: 0;
}

.sticker-image {
	position: relative;
	z-index: 0;
}

.disabled-image {
	position: absolute;
	width: 100%;
	max-width: 350px;
	height: 120px;
	top: 0;
	left: 0;
	background: var(--v-cardBg-base) !important;
	opacity: 0.8;
	z-index: 9;
}

.name-edit-icon {
	position: absolute;
	right: 45%;
	top: 0px;
	transition: 0.3s ease-in-out;
}

.edit-icon {
	position: absolute;
	top: -10px;
	right: 20%;
	z-index: 2;
	transition: 0.2s ease-in-out;
}

.sticker-header .drag-icon {
	position: absolute;
	top: 0px;
	right: 0px;
}

.bits-btn {
	position: relative;
}

.bits-dropdown {
	height: 200px;
	overflow-y: scroll;
}

.twitter-icon,
.discord-icon {
	position: absolute;
	left: 5px;
	top: 20px;
}

@media screen and (min-width: 1264px) and (max-width: 1340px) {
	.text-xs {
		font-size: 11px !important;
	}
	.text-md {
		font-size: 13px !important;
	}
}
</style>
