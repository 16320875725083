<template>
	<div>
		<v-row class="mt-5 pb-3">
			<template v-if="!isTTS">
				<v-col cols="12" md="6" lg="7" xl="8">
					<HowItWorks
						v-if="isCustom && howItWorksData.initialCustomSlots"
						:stickerType="stickerType"
						:data="howItWorksData">
						<template #text>
							<p class="fs--13 fw--400 fieldFore--text">
								Use this area to upload and manage your
								<span class="pink--text fs--14 fw--500">custom stickers</span>
								. You'll start with
								<span class="pink--text fs--14 fw--500">
									{{ howItWorksData.initialCustomSlots }} sticker slots
								</span>
								and earn
								<span class="pink--text fs--14 fw--500">additional slots</span>
								as your viewers use bits on stickers in your channel.
								<br />
								<br />
								You can earn up to
								<span class="pink--text fs--14 fw--500">{{ howItWorksData.maxCustomSlots }}</span>
								additional sticker slots!
							</p>
						</template>
					</HowItWorks>
					<HowItWorks v-if="isReward" :stickerType="stickerType">
						<template #text>
							<p class="fs--13 fw--400 fieldFore--text">
								Use this area to upload
								<span class="pink--text fs--14 fw--500">Reward Stickers</span>
								for your channel.
							</p>
							<p class="fs--13 fw--400 fieldFore--text">
								Like achievements, your viewers can
								<span class="pink--text fs--14 fw--500">
									unlock the ability to use these rewards as they use bits on stickers.
								</span>
							</p>
							<p v-if="isBitsEnabled" class="fs--13 fw--400 fieldFore--text">
								For best results, add Reward Stickers that are both unique and special for your channel.
							</p>
							<p v-if="!isBitsEnabled" class="fs--13 fw--400 fieldFore--text">
								You must be Twitch Affiliate level or higher to upload reward stickers.
								<a
									href="https://help.twitch.tv/s/article/joining-the-affiliate-program"
									target="_blank">
									More Info
								</a>
							</p>
						</template>
					</HowItWorks>
					<HowItWorks v-if="isGlobal" :stickerType="stickerType">
						<template #text>
							<p class="fs--13 fw--400 fieldFore--text">
								Use this section to manage your set of
								<span class="pink--text fs--14 fw--500">Global Stickers</span>
								that come pre-loaded with the extension.
							</p>
							<p class="fs--13 fw--400 fieldFore--text">
								You can adjust the settings of each individual sticker or use the
								<span class="pink--text fs--14 fw--500">Quick Adjust</span>
								feature to edit them all at once.
							</p>
						</template>
					</HowItWorks>
				</v-col>
				<v-col cols="12" md="6" lg="5" xl="4">
					<QuickAdjust :stickerType="stickerType" @onGlobalChangesMade="actions.onGlobalChangesMade" />
				</v-col>
			</template>
			<template v-if="isTTS">
				<v-col cols="12" md="8" lg="7" xl="8">
					<HowItWorks :stickerType="stickerType"></HowItWorks>
				</v-col>
				<v-col cols="12" md="4" lg="5" xl="4">
					<QuickAdjust :stickerType="stickerType" @onGlobalChangesMade="actions.onGlobalChangesMade" />
				</v-col>
			</template>
		</v-row>

		<Banners v-if="isBannerEnabled" />

		<Tips :stickerType="stickerType" @updateStatus="actions.updateStatus" />

		<!-- Demo Video -->
		<TTSTutorial v-if="isTTS && !isGlobalTTS" />

		<TwitterFollow v-if="false && isCustom" :popup="twitterPopup" @close="twitterPopup = false" />

		<DiscordFollow v-if="false && isCustom" :popup="discordPopup" @close="discordPopup = false" />

		<div>
			<draggable
				v-if="!isGlobal"
				class="list-group row mt-3"
				tag="transition-group"
				:component-data="{
					type: 'transition-group',
					name: !drag ? 'flip-list' : null,
				}"
				handle=".drag-icon"
				v-bind="dragOptions"
				@start="drag = true"
				@end="actions.onCardDragEnd">
				<template>
					<v-col
						cols="12"
						sm="6"
						md="4"
						lg="3"
						v-for="(sticker, index) in filteredStickers"
						:key="sticker.key">
						<!-- Custom Sticker -->
						<template v-if="isCustom && sticker.isSlotConfigured">
							<CustomSticker
								:sticker="sticker"
								:stickerType="stickerType"
								:isExtensionOnline="isExtensionOnline"
								@onViewAllTags="actions.viewAllTags"
								@onDeleteSticker="actions.onDeleteSticker"
								@onRangeDataStart="actions.onRangeDataStart"
								@onRangeDataEnd="actions.onRangeDataEnd"
								@onSortFirst="actions.onSortFirst"
								@onSortLast="actions.onSortLast" />
						</template>

						<!-- TTS Sticker -->
						<template v-if="isTTS && sticker.isSlotConfigured">
							<GlobalTTSSticker
								v-if="stickerType === StickerType.GLOBAL_AI_TTS"
								:sticker="sticker"
								:stickerType="stickerType"
								:isExtensionOnline="isExtensionOnline"
								@onNoBitsLockedFeature="actions.onNoBitsLockedFeature"
								@onViewAllTags="actions.viewAllTags"
								@onRangeDataStart="actions.onRangeDataStart"
								@onRangeDataEnd="actions.onRangeDataEnd" />

							<TTSSticker
								v-if="stickerType === StickerType.TTS"
								:sticker="sticker"
								:stickerType="stickerType"
								:stickerSlot="index"
								:editLoading="isEditLoading"
								:isExtensionOnline="isExtensionOnline"
								@onViewAllTags="actions.viewAllTags"
								@onEditSticker="actions.onEditSticker"
								@onDeleteSticker="actions.onDeleteSticker"
								@onRangeDataStart="actions.onRangeDataStart"
								@onRangeDataEnd="actions.onRangeDataEnd" />
						</template>

						<!-- Reward Sticker -->
						<template v-if="isReward && sticker.isSlotConfigured">
							<RewardSticker
								:sticker="sticker"
								:stickerType="stickerType"
								:isExtensionOnline="isExtensionOnline"
								@onViewAllTags="actions.viewAllTags"
								@onDeleteSticker="actions.onDeleteSticker"
								@onRangeDataStart="actions.onRangeDataStart"
								@onRangeDataEnd="actions.onRangeDataEnd" />
						</template>

						<!-- Unlocked Slot -->
						<template v-if="!sticker.isSlotConfigured && sticker.isSlotUnlocked">
							<UnlockedSlot
								:sticker="sticker"
								:stickerType="stickerType"
								:stickerSlot="index"
								:isExtensionOnline="isExtensionOnline"
								@onOpenUploadWizard="actions.onOpenUploadWizard" />
						</template>

						<!-- Locked Slot -->
						<template v-if="!sticker.isSlotConfigured && !sticker.isSlotUnlocked">
							<LockedSlot
								:sticker="sticker"
								:stickerType="stickerType"
								:stickerSlot="index"
								:isExtensionOnline="isExtensionOnline"
								@onNoBitsTryUpload="actions.onNoBitsTryUpload"
								@onOpenTwitterFollow="twitterPopup = true"
								@onOpenDiscordFollow="discordPopup = true" />
						</template>
					</v-col>
				</template>
			</draggable>

			<v-row v-if="isGlobal" class="mt-3">
				<v-col cols="12" sm="6" md="4" lg="3" v-for="sticker in filteredStickers" :key="sticker.key">
					<template v-if="sticker.isSlotConfigured">
						<GlobalSticker
							:sticker="sticker"
							:stickerType="stickerType"
							:isExtensionOnline="isExtensionOnline"
							@onRangeDataStart="actions.onRangeDataStart"
							@onRangeDataEnd="actions.onRangeDataEnd" />
					</template>

					<!-- Unlocked Slot -->
					<template v-if="!sticker.isSlotConfigured && sticker.isSlotUnlocked">
						<UnlockedSlot
							:sticker="sticker"
							:stickerType="stickerType"
							:isExtensionOnline="isExtensionOnline"
							@onOpenUploadWizard="actions.onOpenUploadWizard" />
					</template>

					<!-- Locked Slot -->
					<template v-if="!sticker.isSlotConfigured && !sticker.isSlotUnlocked">
						<LockedSlot
							:sticker="sticker"
							:stickerType="stickerType"
							:isExtensionOnline="isExtensionOnline"
							@onNoBitsTryUpload="actions.onNoBitsTryUpload"
							@onOpenTwitterFollow="twitterPopup = true"
							@onOpenDiscordFollow="discordPopup = true" />
					</template>
				</v-col>
			</v-row>

			<div v-if="stickerList.items.length > 0" v-intersect="handleScroll"></div>
		</div>

		<NonAffiliatePopup :modal="showNoBitsPopup" @close="showNoBitsPopup = false">
			<template>Sorry! You must be Twitch Affiliate level or higher to upload a sticker into this slot.</template>
		</NonAffiliatePopup>

		<NonAffiliatePopup :modal="showNoBitsGlobalTtsPopup" @close="showNoBitsGlobalTtsPopup = false">
			<template>Sorry! You must be Twitch Affiliate level or higher to enable Global A.I. TTS Stickers.</template>
		</NonAffiliatePopup>

		<GenericPopup v-if="errorDialog.show" :modal="errorDialog.show" @close="errorDialog.show = false">
			<template #primaryHeader>{{ errorDialog.primaryHeader }}</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					{{ errorDialog.copy }}
				</p>
			</template>
		</GenericPopup>

		<!-- Tags Dialog -->
		<GenericPopup v-if="tagsPopup" :modal="tagsPopup" :closeButton="false" @close="tagsPopup = false">
			<template #primaryHeader>Search Terms</template>
			<template>
				<div class="text-left">
					<span class="white--text fs--16 fw--500">Sticker Search Terms</span>
					<br />
					<span class="fs--12 fw--500 fw--500 shinyPurple--text">
						Help your users find this sticker with descriptive terms (optional)
					</span>
				</div>

				<v-row class="mb-0 mt-1">
					<v-col cols="12" sm="8" md="8" class="py-1">
						<v-text-field
							v-model="tag"
							background-color="purple"
							maxlength="15"
							placeholder="Enter Search Term"
							class="mt-2"
							solo
							dense
							hide-details
							@keyup.enter="actions.addTag"></v-text-field>
					</v-col>
					<v-col cols="12" sm="4" md="4" class="py-1">
						<v-btn color="pink" class="mt-0 mt-md-2" block depressed @click="actions.addTag">
							<v-icon class="mr-1" size="20">{{ icons.plus }}</v-icon>
							Add
						</v-btn>
					</v-col>
				</v-row>

				<template v-if="tagList.length">
					<div class="purple mt-3 pt-3 pb-2 pl-3 pr-1">
						<div class="tags-section text-left">
							<v-chip
								v-for="(item, index) in tagList"
								:key="index"
								color="info"
								small
								class="mr-1 mb-2 pl-3 pr-2 py-3">
								<span class="fs--13 fw--400">{{ item }}</span>
								<v-icon size="18" class="ml-2" color="black" @click="actions.removeTag(index)">
									{{ icons.close }}
								</v-icon>
							</v-chip>
						</div>
					</div>
				</template>
			</template>
		</GenericPopup>

		<!-- Global TTS Enable Popup -->
		<GlobalTTSPopup
			:modal="showGlobalTtsPopup"
			@skip="actions.skipGlobalPopup"
			@close="showGlobalTtsPopup = false" />
		<ContinueGlobalTTSPopup :modal="showGlobalTtsContinuePopup" @close="actions.closeGlobalPopups" />
	</div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount, reactive, ref } from '@vue/composition-api';
import store from '@/store';
import axios from '@axios';
import { StickerType } from '../../../utils/constants';
import { FormChangeWatcher } from '../../../utils/form';
import { isBitsEnabled } from '../../../utils/auth';
import HowItWorks from './HowItWorks.vue';
import QuickAdjust from './QuickAdjust.vue';
import TwitterFollow from './TwitterFollow.vue';
import DiscordFollow from './DiscordFollow.vue';
import CustomSticker from './Type/Custom.vue';
import RewardSticker from './Type/Reward.vue';
import GlobalSticker from './Type/Global.vue';
import TTSSticker from './Type/TTS.vue';
import GlobalTTSSticker from './Type/GlobalTTS.vue';
import UnlockedSlot from './Type/UnlockedSlot.vue';
import LockedSlot from './Type/LockedSlot.vue';
import NonAffiliatePopup from '@/components/NonAffiliatePopup';
import GenericPopup from '@/components/GenericPopup';
import Tips from './Tips.vue';
import TTSTutorial from './TTSTutorial.vue';
import Banners from '@/views/streamer/home/promotion/Banners.vue';
import GlobalTTSPopup from '@/components/GlobalTTSPopup.vue';
import ContinueGlobalTTSPopup from '@/components/ContinueGlobalTTSPopup.vue';

import { saveEvent } from '@/utils/gtag';
import { mdiDrag, mdiYoutube, mdiCloseCircle, mdiPlusCircle } from '@mdi/js';

export default {
	props: {
		stickerType: {
			type: String,
			required: true,
		},
		isExtensionOnline: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		HowItWorks,
		QuickAdjust,
		TwitterFollow,
		DiscordFollow,
		UnlockedSlot,
		LockedSlot,
		CustomSticker,
		RewardSticker,
		GlobalSticker,
		TTSSticker,
		GlobalTTSSticker,
		NonAffiliatePopup,
		GenericPopup,
		Tips,
		TTSTutorial,
		Banners,
		GlobalTTSPopup,
		ContinueGlobalTTSPopup,
	},
	emits: ['onOpenUploadWizard', 'onOpenEditWizard'],
	setup(props, ctx) {
		// Watchers for data changes.
		const formChangeWatchers = [];

		// Turn off all watches.
		const stopWatching = () => {
			formChangeWatchers.forEach(w => {
				w.stopWatching();
			});
			formChangeWatchers.length = 0;
		};

		// Add watches for all stickers.
		const startWatching = items => {
			items.forEach(e => {
				const w = new FormChangeWatcher();
				formChangeWatchers.push(w);
				w.startWatching(e, actions.saveChanges, () => {
					return true;
				});
			});
		};

		const isEditLoading = ref(false);

		onMounted(() => {
			// Show global AI TTS dialog if all global AI TTS stickers are disabled and if we have not shown the dialog before.
			if (!store.state.app.analytics.anyGlobalTtsEnabled) {
				const shown = localStorage.getItem('_globalTtsEnabledPopup');
				if (!shown) {
					showGlobalTtsPopup.value = true;
					localStorage.setItem('_globalTtsEnabledPopup', 'true');
				}
			}
		});

		onBeforeUnmount(() => {
			stopWatching();
		});

		// Dialog for a non-affiliate user.
		const showNoBitsPopup = ref(false);
		const showNoBitsGlobalTtsPopup = ref(false);

		const twitterPopup = ref(false);
		const discordPopup = ref(false);
		const hideDisabled = ref(false);

		const tagsPopup = ref(false);
		const tag = ref('');
		const tagList = reactive([]);

		// Global TTS enable popup.
		const showGlobalTtsPopup = ref(false);
		const showGlobalTtsContinuePopup = ref(false);

		// Used for save errors.
		const errorDialog = reactive({
			show: false,
			primaryHeader: 'Error',
			copy: 'An error occurred.',
		});

		// Pagination for global sticker.
		const globalPagination = reactive({
			totalPageCount: 0,
			totalItemCount: 0,
			itemsPerPage: 24,
			page: 0,
			itemCount: 0,
		});

		// Model values from server.
		const stickerList = reactive({ items: [] }); // Result from server.
		const badgeCount = ref(0);

		// Drag / drop sticker cards.
		const tab = ref(0);
		const drag = ref(false);
		const dragOptions = computed(() => {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			};
		});

		const howItWorksData = reactive({
			initialCustomSlots: null,
			maxCustomSlots: null,
			intPercentReached: null,
			currentStreamerBits: null,
			nextSlotBits: null,
		});

		const filteredStickers = computed(() => {
			if (props.stickerType == StickerType.REWARD) {
				return stickerList.items;
			} else if (hideDisabled.value) {
				return stickerList.items.filter(sticker => sticker.enabled);
			}
			return stickerList.items;
		});

		const configuredSlots = computed(() => {
			return filteredStickers.value.filter(sticker => sticker.isSlotConfigured);
		});

		const currentSearchSticker = ref(null);

		// Banners.
		const isBannerEnabled = computed(() => {
			return props.stickerType == StickerType.CUSTOM;
		});

		// Moves a sticker to the specified new index in the list.
		const moveSticker = (uuid, newIndex) => {
			const oldIndex = stickerList.items.findIndex(item => item.uuid === uuid);
			if (oldIndex == newIndex) {
				return;
			}

			axios
				.put(`/custom-sticker/sort-order/${uuid}/${newIndex}`)
				.then(() => {
					actions.loadData();
				})
				.catch(error => console.log(error));
		};

		const actions = {
			viewAllTags(sticker) {
				currentSearchSticker.value = sticker;
				tagsPopup.value = true;
				tagList.length = 0;
				if (sticker.searchTerms && sticker.searchTerms.length > 0) {
					sticker.searchTerms.forEach(item => {
						tagList.push(item);
					});
				}
			},
			addTag() {
				if (tag.value) {
					tagList.push(tag.value);
					tag.value = '';
					currentSearchSticker.value.searchTerms = [];
					currentSearchSticker.value.searchTerms.push(...tagList);
				}
			},
			removeTag(index) {
				tagList.splice(index, 1);
				currentSearchSticker.value.searchTerms = [];
				currentSearchSticker.value.searchTerms.push(...tagList);
			},
			// Load stickers.
			loadData: append => {
				let url;
				if (props.stickerType == StickerType.GLOBAL) {
					url = `/global-stickers?isTts=false&page=${globalPagination.page}&size=${globalPagination.itemsPerPage}`;
				} else if (props.stickerType == StickerType.GLOBAL_AI_TTS) {
					url = `/global-stickers?isTts=true&page=${globalPagination.page}&size=${globalPagination.itemsPerPage}`;
				} else {
					url = `/custom-stickers/${props.stickerType}`;
				}

				// Clear existing watches, only if not appending data.
				if (!append) {
					stopWatching();
				}

				axios
					.get(url)
					.then(res => {
						// We append data for global sticker pagination.
						if (!append) {
							stickerList.items.length = 0;
						}

						let items;
						if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
							items = res.data.items;
							Object.assign(globalPagination, res.data.pagination);
						} else {
							items = res.data.items;
							badgeCount.value = res.data.openStickerSlotCount;
						}

						const ttsStickers = [];

						items.forEach(e => {
							stickerList.items.push(e);
							if (e.stickerType === StickerType.TTS && e.tts && e.tts.bounds) {
								ttsStickers.push(e);
							}
						});

						// Add watches.
						startWatching(stickerList.items);
					})
					.finally(() => {});
			},

			updateStatus: value => {
				// Display Enabled / Disabled Stickers
				hideDisabled.value = value;
			},

			loadNextPage: () => {
				globalPagination.page++;
				actions.loadData(true);
			},

			loadHowItWorks: () => {
				axios.get(`/custom-sticker/how-it-works/${props.stickerType}`).then(res => {
					Object.assign(howItWorksData, res.data);
				});
			},

			saveChanges: sticker => {
				let url;
				if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
					url = '/global-sticker';
				} else {
					url = '/custom-sticker/' + props.stickerType;
				}

				// Turn off watches since we may be making changes.
				stopWatching();

				if (props.stickerType == StickerType.TTS) {
					sticker.tts.stickiness = sticker.stickiness;
					sticker.tts.volume = sticker.volume;
					sticker.tts.sku = sticker.sku;
					sticker.tts.subOnly = sticker.subOnly;

					if (sticker.alertSoundBlob) {
						isEditLoading.value = true;
					}
				}

				axios
					.put(url, sticker)
					.then(resp => {
						const data = { ...resp.data };

						// Check for changed values after save.
						if (data && data.isStickerPlacementEnabled) {
							sticker.isStickerPlacementEnabled = data.isStickerPlacementEnabled;
						}

						// Reload sticker if alert sound was changed.
						if (props.stickerType == StickerType.TTS && sticker.tts && sticker.tts.alertSoundBlob) {
							const stickerIndex = stickerList.items.findIndex(item => item.id === data.id);
							if (stickerIndex >= 0) {
								const items = [...stickerList.items];
								items[stickerIndex] = { ...data };

								stickerList.items = [...items];
							}
						}

						// Add watches.
						startWatching(stickerList.items);
					})
					.catch(error => {
						console.error(error);
						if (error.response) {
							errorDialog.copy = error.response.data.error;
						} else {
							errorDialog.copy = error;
						}
						errorDialog.show = true;
					})
					.finally(() => {
						isEditLoading.value = false;
					});
			},

			onOpenUploadWizard: data => {
				ctx.emit('onOpenUploadWizard', data);

				saveEvent({
					event: 'sticker_upload_begin',
					params: {
						event_category: 'engagement',
						event_label: 'User Starts the Sticker Upload Process',
						item_category: data.type,
					},
				});
			},

			onEditSticker(data) {
				ctx.emit('onOpenEditWizard', {
					type: data.type,
					stickerSlot: data.stickerSlot,
					sticker: data.sticker,
					edit: true,
				});
			},

			onNoBitsTryUpload: () => {
				showNoBitsPopup.value = true;
			},

			onNoBitsLockedFeature: () => {
				showNoBitsGlobalTtsPopup.value = true;
			},

			onDeleteSticker: uuid => {
				if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
					throw new Error('Cannot delete a global sticker.');
				}

				axios.delete(`/custom-sticker/${uuid}`).then(() => {
					actions.loadData();
				});
			},

			onGlobalChangesMade: () => {
				// Reset page to zero for global stickers if global changes are applied.
				if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
					globalPagination.page = 0;
				}

				actions.loadData();
			},
			// A slider or text field has started to change. We check this to disable the watch on
			// data changes until the final change is complete to avoid many server calls.
			onRangeDataStart: sticker => {
				const w = formChangeWatchers.find(e => {
					return e.data.uuid == sticker.uuid;
				});
				w.onRangeDataStart();
			},
			// Slider or text change change is complete so we save changes.
			onRangeDataEnd: sticker => {
				const w = formChangeWatchers.find(e => {
					return e.data.uuid == sticker.uuid;
				});
				w.onRangeDataEnd();
			},

			// When the card is dropped, call the server to re-order the stickers.
			onCardDragEnd: e => {
				drag.value = false;

				// Ensure that the sticker is not being dropped in an empty slot area.
				// If it is, set the proper index and reload.
				const firstOpenSlot = stickerList.items.findIndex(e => {
					return !e.isSlotConfigured;
				});

				// If dragging over an open slot, just reload.
				if (firstOpenSlot > -1 && e.newIndex >= firstOpenSlot) {
					actions.loadData();
					return;
				}

				const sticker = stickerList.items[e.oldIndex];
				moveSticker(sticker.uuid, e.newIndex);
			},

			onSortFirst: function (uuid) {
				moveSticker(uuid, 0);
			},

			onSortLast: function (uuid) {
				moveSticker(uuid, configuredSlots.value.length - 1);
			},

			skipGlobalPopup: function () {
				showGlobalTtsPopup.value = false;
				showGlobalTtsContinuePopup.value = true;
			},

			closeGlobalPopups: function () {
				showGlobalTtsContinuePopup.value = false;
			},
		};

		const handleScroll = (entries, observer, isIntersecting) => {
			if (isIntersecting) {
				setTimeout(() => {
					if (globalPagination.totalPageCount - 1 > globalPagination.page) {
						actions.loadNextPage();
					}
				}, 500);
			}
		};

		// Load data.
		actions.loadData();

		if (props.stickerType == StickerType.CUSTOM) {
			actions.loadHowItWorks();
		}

		// Load voices.
		if (props.stickerType == StickerType.TTS) {
			axios.get('/custom-sticker/tts/voices').then(res => {
				store.commit('app/SET_TTS_VOICES', res.data);
			});
		}

		return {
			tag,
			tagList,
			isEditLoading,
			hideDisabled,
			stickerList,
			filteredStickers,
			badgeCount,
			globalPagination,
			isBannerEnabled,
			actions,
			handleScroll,
			howItWorksData,
			tab,
			drag,
			dragOptions,
			StickerType,
			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			isTTS: props.stickerType == StickerType.TTS || props.stickerType === StickerType.GLOBAL_AI_TTS,
			isGlobalTTS: props.stickerType === StickerType.GLOBAL_AI_TTS,
			errorDialog,
			tagsPopup,
			showNoBitsPopup,
			showNoBitsGlobalTtsPopup,
			isBitsEnabled,
			twitterPopup,
			discordPopup,
			showGlobalTtsPopup,
			showGlobalTtsContinuePopup,
			icons: {
				drag: mdiDrag,
				youtube: mdiYoutube,
				close: mdiCloseCircle,
				plus: mdiPlusCircle,
			},
		};
	},
};
</script>

<style scoped>
.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0s;
}

.ghost {
	opacity: 0.5;
}

.list-group {
	min-height: 20px;
}

.list-group-item {
	cursor: move;
}

.list-group-item i {
	cursor: pointer;
}

.tags-section {
	height: 80px;
	overflow-y: auto;
}
</style>
