<template>
	<div>
		<v-dialog :max-width="$vuetify.breakpoint.mdAndUp ? 680 : 535" v-model="modal" :persistent="true">
			<v-card color="black" class="pa-0 content-section">
				<v-icon color="white" class="close-icon bungee" @click="actions.skip">{{ closeIcon }}</v-icon>

				<div class="content text-center pt-12 pb-6">
					<h1 class="fs--36 fw--800 mb-0">Global A.I. TTS Stickers are here!</h1>
					<h4 class="fs--24 fw--800 mb-10">
						Tons of All New
						<span class="yellow--text">Fully Animated</span>
						Text-to-Speech Stickers
					</h4>

					<div class="stickers d-flex">
						<img
							src="@/assets/images/global-tts/Bender-sample.gif"
							width="200"
							height="240"
							class="image-1"
							alt="Bender" />
						<img
							src="@/assets/images/global-tts/Jack sparrow-sample.gif"
							width="230"
							height="230"
							class="image-2"
							alt="Jack Sparrow" />
						<img
							src="@/assets/images/global-tts/VADER-sample.gif"
							width="240"
							height="250"
							class="image-3"
							alt="Vader" />
						<img
							src="@/assets/images/global-tts/Dr Phil-sample.gif"
							width="138"
							height="250"
							class="image-4"
							alt="Dr Phil" />
						<img
							src="@/assets/images/global-tts/SPONGEBOB-sample.gif"
							width="280"
							height="220"
							class="image-5"
							alt="Spongee Bob" />
					</div>

					<v-btn color="pink" class="mt-5 py-6" depressed @click="actions.enableAll">
						Click here to enable them all!
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { watch } from '@vue/composition-api';
import router from '@/router';
import axios from '@axios';
import { trackEvent } from '@/utils/mixpanel';
import { getUserId } from '@/utils/auth';
import { saveEvent } from '@/utils/gtag';
import { mdiClose } from '@mdi/js';

export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, ctx) {
		// Track open event.
		watch(
			() => props.modal,
			() => {
				if (props.modal) {
					trackEvent({
						type: 'Campaign_Open',
						params: {
							campaign_name: 'Global A.I. TTS',
							page: getPageName(),
						},
					});
				}
			},
		);

		const getPageName = () => {
			if (router.currentRoute.meta && router.currentRoute.meta.page) {
				return router.currentRoute.meta.page;
			} else {
				return router.currentRoute.name;
			}
		};

		const actions = {
			enableAll() {
				const id = getUserId();

				// TODO consoldate tracking into one call.

				// GA
				saveEvent({
					event: 'tts_enable_all_click',
					params: {
						event_category: 'engagement',
						event_label: 'User Enabled All Global TTS Stickers',
						user_id: id,
					},
				});

				// MP.
				trackEvent({
					type: 'Campaign_Interaction',
					params: {
						campaign_name: 'Global A.I. TTS',
						button_name: 'Enable All',
						page: getPageName(),
					},
				});

				const payload = {
					isEnabled: true,
					isTts: true,
				};

				axios
					.put('/global-sticker/global-status', payload)
					.then(() => {
						ctx.emit('close');
					})
					.catch(error => console.log(error));
			},

			skip() {
				saveEvent({
					event: 'tts_enable_all_click',
					params: {
						event_category: 'engagement',
						event_label: 'User Enabled All Global TTS Stickers',
					},
				});
				ctx.emit('skip');
			},
		};

		return {
			closeIcon: mdiClose,
			actions,
		};
	},
};
</script>

<style lang="scss" scoped>
.content-section {
	background: url('../assets/images/global-tts/Global-TTS-Popup-Bg.png');
	background-repeat: no-repeat;
	background-size: 100% 95%;
	overflow-x: hidden;

	h1 {
		line-height: 1.7rem;
	}

	content {
		overflow-x: hidden;
	}

	.bungee {
		font-family: 'Bungee', sans-serif !important;
		font-weight: 400;
		font-style: normal;
	}

	.close-icon {
		position: absolute;
		top: 2px;
		right: 5px;
	}

	.yellow--text {
		color: #fef602;
	}

	.image-1 {
		transform: rotate(-10deg);
		margin-left: -35px;
		margin-top: -10px;
		z-index: 1;
	}
	.image-2 {
		transform: rotate(-5deg);
		margin-left: -100px;
		margin-top: -20px;
		z-index: 2;
	}
	.image-3 {
		transform: rotate(5deg);
		margin-left: -80px;
		margin-top: -20px;
		z-index: 1;
	}
	.image-4 {
		transform: rotate(-10deg);
		margin-left: -55px;
		margin-top: -30px;
		z-index: 3;
	}
	.image-5 {
		transform: rotate(30deg);
		margin-left: -44px;
		margin-top: 0px;
		z-index: 3;
	}
}
</style>
